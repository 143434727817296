// TODO: Mobile Navigation

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
// import { BsBag } from "react-icons/bs"
// import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context"

import Navigation from "./Navigation"
import { device } from "../../lib/Devices"

const TopBar = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #fffbf4;
  height: 100px;
  div {
    display: flex;
    align-items: center;
  }
`

const InnerHeader = styled.div`
  width: 100vw;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  flex-wrap: wrap;
  @media ${device.tablet} {
    height: 100px;
  }
`

const LeftHeader = styled.div`
  width: 30%;
  justify-content: flex-start;
  padding-left: 20px;
  button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    color: #313332;
  }
`
const MiddleHeader = styled.div`
  display: none;
  justify-content: center;
  width: 40%;
  @media ${device.tablet} {
    display: flex;
  }
  a {
    text-decoration: none;
    font-size: 1.8rem;
    color: #313332;
    margin: 0;
    font-weight: 700;
    font-style: italic;
    text-align: center;

    @media ${device.laptop} {
      font-size: 2.2rem;
    }
  }
`

const RightHeader = styled.div`
  width: 22%;
  margin-left: 8%;
  justify-content: flex-end;

  .right-nav {
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: flex-end;
  }
`

// const CartButton = styled.button`
//   background: transparent;
//   border: none;
//   margin: 0 15px 0 0;
//   padding-top: 5px;

//   .cart-indicator {
//     color: white;
//     position: relative;
//     background: #ff5400;
//     border-radius: 10px;
//     height: 10px;
//     width: 10px;
//     top: -37px;
//     right: -18px;
//   }
// `
//
// const CustomerLogin = styled.button`
//   display: none;
//   @media ${device.tablet} {
//     display: block;
//     background: transparent;
//     border: none;
//     padding: 0;
//     margin-right: 20px;
//     text-decoration: none;
//     color: #313332;
//     font-size: 1.2rem;
//     font-weight: 700;
//     font-style: italic;
//     border-bottom: 2px transparent solid;
//     transition: 0.3s ease border;
//     &:hover {
//       border-bottom: 2px #313332 solid;
//     }
//   }
// `
// const StyledIcon = styled(BsBag)`
//   color: black;
//   height: 25px;
//   width: 25px;
// `

const Hamburger = styled.button`
  height: 40px;
`

const Header = ({ toggle }) => {
  const [isNavOpen, setIsNavOpen] = React.useState(false)

  // const { state } = React.useContext(SnipcartContext)
  // const { userStatus, cartQuantity } = state

  return (
    <TopBar>
      <InnerHeader>
        <Navigation isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
        <LeftHeader>
          <Hamburger onClick={setIsNavOpen}>
            <svg viewBox="0 0 100 80" width="40" height="40">
              <rect width="100" height="15" rx="8"></rect>
              <rect y="30" width="100" height="15" rx="8"></rect>
              <rect y="60" width="100" height="15" rx="8"></rect>
            </svg>
          </Hamburger>
        </LeftHeader>
        <MiddleHeader>
          <Link to="/">Long Time Gone</Link>
        </MiddleHeader>
        <RightHeader>
          {/* <div className="right-nav">
            {userStatus === "SignedOut" ? (
              <CustomerLogin className="snipcart-customer-signin">
                <span>Sign In</span>
              </CustomerLogin>
            ) : (
              <CustomerLogin className="snipcart-customer-signin">
                <span>My Orders</span>
              </CustomerLogin>
            )}
            <CartButton aria-label="Open Cart" className="snipcart-checkout">
              <StyledIcon />
              {cartQuantity > 0 && <div className="cart-indicator"></div>}
            </CartButton>
          </div> */}
        </RightHeader>
      </InnerHeader>
    </TopBar>
  )
}

export default Header

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { device } from "../../lib/Devices"

const StyledFooter = styled.footer`
  width: 100vw;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background: #313332;

  @media ${device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media ${device.laptop} {
    padding: 50px;
  }

  section {
    margin-bottom: 50px;
    color: #ff5400;
    @media ${device.tablet} {
      min-width: 40%;
      text-align: center;
    }
    @media ${device.laptop} {
      min-width: 0;
      text-align: left;
      margin-bottom: 0;
    }
    p {
      color: #fffbf4;
    }
    ul {
      list-style: none;
      margin: 0;
      li {
        a {
          color: #fffbf4;
          background: none;
          border: none;
          padding: 0;
          border-bottom: 2px transparent solid;
          transition: 0.3s ease border;
          &:hover {
            border-bottom: 2px #fffbf4 solid;
          }
        }
      }
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      {/* <section>
        <h3>Store</h3>
        <ul>
          <li>
            <Link to="/store/all-products">All Products</Link>
          </li>
          <li>
            <Link to="/store/accessories">Accessories</Link>
          </li>
          <li>
            <Link to="/store/custom-jackets">Custom Jackets</Link>
          </li>
          <li>
            <Link to="/store/custom-jeans">Custom Jeans</Link>
          </li>
        </ul>
      </section> */}
      <section>
        <h3>About</h3>
        <ul>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/bespoke">Bespoke</Link>
          </li>
          {/* <li>
            <Link to="/shipping">Shipping</Link>
          </li>
          <li>
            <Link to="/privacy-returns">Privacy and Returns</Link>
          </li> */}
        </ul>
      </section>
      <section>
        <h3>Contact</h3>
        <ul>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            <a href="mailto:hello@longtimeg.one">hello@longtimeg.one</a>
          </li>
          <li>
            <a href="tel:+441134366343">0113 436 6343</a>
          </li>
        </ul>
      </section>
      <section>
        <h3>Follow</h3>
        <ul>
          <li>
            <a href="https://www.instagram.com/long.time.gone">Instagram</a>
          </li>
          <li>
            <a href="https://www.facebook.com/LongTimeGoneLeeds">Facebook</a>
          </li>
          <li>
            <a href="https://uk.linkedin.com/in/kellye-coffyn-3b53391b1">
              LinkedIn
            </a>
          </li>
        </ul>
      </section>
      <section style={{ fontSize: "0.7rem" }}>
        <p>
          © {new Date().getFullYear()}, Long Time Gone
          <br />
          Website build by cfyn.tech
        </p>
      </section>
    </StyledFooter>
  )
}

export default Footer

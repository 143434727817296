import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import { device } from "../../lib/Devices"

const SidebarNav = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 40px;
  background: #db6d4a;
  z-index: 11;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.2),
    0 4px 4px rgba(0, 0, 0, 0.2), 0 8px 8px rgba(0, 0, 0, 0.2),
    0 16px 16px rgba(0, 0, 0, 0.2), 0 32px 32px rgba(0, 0, 0, 0.2);
  @media ${device.laptop} {
    max-width: 700px;
    width: 50%;
    height: 100vh;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  height: 44px;
  background: transparent;
  border: none;

  .close-icon {
    path {
      margin: 0;
    }
  }
`

const MainLinks = styled.ul`
  font-size: 1.5rem;
  list-style: none;
  margin: 0;
  font-style: italic;
  @media ${device.tablet} {
    font-size: 2rem;
  }
  li {
    margin: 20px 0 0;
    @media ${device.tablet} {
      margin: 40px 0 0;
    }
    a,
    button {
      color: #fffbf4;
      background: none;
      border: none;
      padding: 0;
      border-bottom: 2px transparent solid;
      transition: 0.3s ease border;
      &:hover {
        border-bottom: 2px #fffbf4 solid;
      }
    }
    button {
      @media ${device.tablet} {
        padding-bottom: 10px;
        margin-bottom: -10px;
      }
    }
  }
`

// const StoreLinks = styled(motion.section)`
//   overflow: hidden;
//   padding: 0;
//   margin: 0;
//   box-sizing: border-box;
//   ul {
//     transform-origin: top center;
//     border-left: 2px white solid;
//     list-style: none;
//     padding: 0;
//     @media ${device.tablet} {
//       margin: 40px 0 10px 20px;
//     }
//     li {
//       padding-left: 20px;
//     }
//   }
// `

const Navigation = ({ isNavOpen, setIsNavOpen }) => {
  // const [isStoreOpen, setIsStoreOpen] = React.useState(false)

  // const handleStoreToggle = () => {
  //   !isStoreOpen ? setIsStoreOpen(true) : setIsStoreOpen(false)
  // }

  return (
    <SidebarNav
      variants={{ open: { x: 0 }, closed: { x: "-102%" } }}
      initial="closed"
      animate={isNavOpen ? "open" : "closed"}
      transition={{ damping: 300 }}
    >
      <CloseButton onClick={() => setIsNavOpen(false)} aria-label="Close">
        <svg
          className="close-icon"
          width="44"
          height="44"
          viewBox="0 0 44 44"
          aria-hidden="true"
          focusable="false"
        >
          <path
            d="M0.549989 4.44999L4.44999 0.549988L43.45 39.55L39.55 43.45L0.549989 4.44999Z"
            fill="#fffbf4"
          />
          <path
            d="M39.55 0.549988L43.45 4.44999L4.44999 43.45L0.549988 39.55L39.55 0.549988Z"
            fill="#fffbf4"
          />
        </svg>
      </CloseButton>
      <MainLinks>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/bespoke">Bespoke</Link>
        </li>
        {/* <li>
          <button onClick={handleStoreToggle}>Store</button>
          <AnimatePresence initial={false}>
            {isStoreOpen && (
              <StoreLinks
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { opacity: 1, height: "auto" },
                  collapsed: { opacity: 0, height: 0 },
                }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                style={{ overflow: "hidden" }}
              >
                <ul>
                  <li>
                    <Link to="/store/all-products">All Products</Link>
                  </li>
                  <li>
                    <Link to="/store/accessories">Accessories</Link>
                  </li>
                  <li>
                    <Link to="/store/custom-jackets">Custom Jackets</Link>
                  </li>
                  <li>
                    <Link to="/store/custom-jeans">Custom Jeans</Link>
                  </li>
                </ul>
              </StoreLinks>
            )}
          </AnimatePresence>
        </li> */}
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </MainLinks>
    </SidebarNav>
  )
}

export default Navigation
